import { getRetailer } from '../data/retailer'
import { getUser } from '../data/user'
import { AppTrackingConfig } from '../types/AppTrackingConfig'
import { configureRaygun, initRaygun } from './errorTracker'
import { configureUbeTracking, UBEConfig } from './ubeTracker'

/**
 * Helper to set up UBE tracking and Raygun error tracking
 */
export const configureAppTracking = ({
  domainPrefix,
  environment,
  raygunApiKey,
  raygunConfig,
  raygunOptions,
  systemName,
  systemVersion,
}: AppTrackingConfig): Promise<void> => {
  // Common config for both Raygun and UBE tracking
  const config = {
    domainPrefix,
    environment,
    systemName,
    systemVersion,
    ...raygunConfig,
  }

  // Initialize Raygun client
  initRaygun(raygunApiKey, config, raygunOptions)

  return Promise.all([getRetailer(), getUser()]).then(([retailer, user]) => {
    const retailerId = retailer?.id || ''
    const retailerAccountStatus = retailer?.account_status || ''
    const userId = user?.id || ''
    // Configure Raygun using retailerID and retailer account status(default values for each request)
    configureRaygun({
      retailerId,
      retailerAccountStatus,
    })
    // Create UBEConfig out of a combination of options object, user ID and retailer ID
    const ubeConfig: UBEConfig = { ...config, retailerId, userId }
    // Configure UBE tracking
    configureUbeTracking(ubeConfig)
  })
}
