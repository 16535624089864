import { ToastNotifications } from '@lightspeed/design-system-react'
import { trackError } from '@vend/utilities'
import { useState } from 'react'
import {
  API_ERRORS,
  PAGE_ROUTE,
  Steps,
  STEPS,
  useGenericErrorMessage,
  useTranslateValidationMessage,
  VALIDATION_MESSAGES,
} from '../constants/constants'
import {
  CredentialsValidationResult,
  InputCredentials,
  MFAType,
  signinUser,
} from './api'

type ValidationError =
  | 'invalidCredentials'
  | 'invalidOTP'
  | 'invalidRecoveryCode'

export const useLogin = (
  showStep: (step: Steps) => void,
  domainPrefix: string,
  requireRecaptcha: boolean,
  getRecaptchaToken: () => Promise<string>,
  handleRecaptchaStatusUpdate: (required: boolean) => void,
  inputCredentials: InputCredentials | null
) => {
  const translateValidation = useTranslateValidationMessage()
  const genericErrMessage = useGenericErrorMessage()

  const urlSearchQuery = new URLSearchParams(window.location.search)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [hasInternalError, setHasInternalError] = useState(false)
  const [validationError, setValidationError] =
    useState<ValidationError | null>()
  const [mfaRequired, setMFARequired] = useState(false)
  const [userDisabled, setUserDisabled] = useState(false)
  const [noLoginAccess, setNoLoginAccess] = useState(false)

  const handleSubmit = async (input = inputCredentials) => {
    setHasInternalError(false)
    setValidationError(null)
    setIsSubmitting(true)

    const credentials = { ...input }

    credentials.domainPrefix = domainPrefix

    const returnURL = urlSearchQuery.get('return')
    if (returnURL) {
      credentials.return = returnURL
    }

    if (requireRecaptcha) {
      credentials['g-recaptcha-response'] = await getRecaptchaToken()
    }

    try {
      if (
        credentials.username === undefined ||
        credentials.username?.length === 0 ||
        credentials.password === undefined ||
        credentials.password?.length === 0
      ) {
        trackError('about to submit empty username and or password')
      }

      const response = await signinUser(credentials)
      const mfaProvided = credentials.mfa !== undefined
      handleResponse(response, mfaProvided)
    } catch (error: any) {
      trackError(error)
      setHasInternalError(true)

      // Show a generic error message if something goes wrong
      // when user is going through the password compromised flow
      // or using recovery code as a second authentication factor.
      // We don't show this error toast for other flows (standard
      // username and password signin and OTP) as the error
      // will be handled within the specific component.
      const recoveryCodeUsed = credentials.mfa?.recovery_code
      const passwordCompromised =
        !credentials.mfa &&
        (credentials.new_password || credentials.bypass_hibp)

      if (recoveryCodeUsed || passwordCompromised) {
        ToastNotifications.negative(genericErrMessage)
      }

      setIsSubmitting(false)
    }
  }

  const handleResponse = (
    response: CredentialsValidationResult,
    mfaProvided: boolean
  ) => {
    handleRecaptchaStatusUpdate(response.require_captcha)

    // Failed authentication, error handling.
    if (!response.valid_credentials) {
      setIsSubmitting(false)

      const reasons = response.reason.split(',')

      const mfaRequired =
        reasons.includes(API_ERRORS.mfaInvalid) && !mfaProvided
      const invalidOTP = reasons.includes(API_ERRORS.mfaInvalid) && mfaProvided
      const passwordChangeRequired = reasons.includes(
        API_ERRORS.requirePasswordChange
      )
      const passwordCompromised = reasons.includes(
        API_ERRORS.passwordComproised
      )
      const invalidCredentials = reasons.includes(API_ERRORS.credentialsInvalid)
      const invalidRecoveryCode = reasons.includes(
        API_ERRORS.recoveryCodeInvalid
      )
      const userDisabled = reasons.includes(API_ERRORS.userDisabled)
      const noLoginAccess = reasons.includes(API_ERRORS.noLoginAccess)

      if (userDisabled) {
        setUserDisabled(true)
        return
      }

      if (passwordChangeRequired) {
        showStep(STEPS.changePassword)
        return
      }

      if (invalidOTP) {
        setValidationError('invalidOTP')
        return
      }

      if (invalidRecoveryCode) {
        setValidationError('invalidRecoveryCode')
        return
      }

      if (invalidCredentials) {
        setValidationError('invalidCredentials')
        return
      }

      if (noLoginAccess) {
        setNoLoginAccess(true)
        return
      }

      if (passwordCompromised) {
        setMFARequired(mfaRequired)
        showStep(STEPS.PasswordCompromisedAlert)
        return
      }

      if (mfaRequired && response.enabled_mfa?.type === MFAType.OTP) {
        showStep(STEPS.OTPAuthentication)
        return
      }

      trackError('unhandled signin response', response)
      ToastNotifications.negative(
        translateValidation(VALIDATION_MESSAGES.generic)
      )
      // Go back to the first step to start over.
      showStep(STEPS.credentialsAuthentication)
      setIsSubmitting(false)
    }

    // Do not redirect if explicitly required not to
    if (response.redirect === false) {
      return
    }

    // Successful authentication, redirect after logged in.
    if (response.redirect) {
      window.location.assign(response.redirect)
      return
    }

    // Default redirect to Web Register.
    window.location.assign(PAGE_ROUTE.webregister)
  }

  return {
    isSubmitting,
    hasInternalError,
    handleSubmit,
    validationError,
    mfaRequired,
    userDisabled,
    noLoginAccess,
  }
}
